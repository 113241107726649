<template>
  <div class="w-full p-3 flex flex-col gap-4 text-darkPurple">
    <div v-if="!showError">
      <h1
        class="w-full h-12 flex flex-col justify-start items-start text-xl font-bold"
      >
        Appearance
        <span
          class="inline-block w-full h-0 p-0"
          style="border: 0.5px solid #d6dbe4"
        />
      </h1>

      <Card class="p-3">
        <p class="font-bold text-lg">Logo & Email Banner</p>
        <p class="mb-3">Update logo and email banner here.</p>

        <div
          class="w-full h-auto flex flex-row justify-between rounded-lg px-3 py-4 bg-white drop-container"
        >
          <Banner
            type="Logo"
            :imageUrl="appearance.logoUrl"
            :btnBackgroundColor="appearance.buttonColor || defaultBTNColor"
            :endPoint="logoEndpoint"
            @update-appearance="updateAppearance"
          />

          <Banner
            type="Banner"
            :imageUrl="appearance.bannerUrl"
            :btnBackgroundColor="appearance.buttonColor || defaultBTNColor"
            :endPoint="bannerEndPoint"
            @update-appearance="updateAppearance"
          />
        </div>
      </Card>

      <div class="line" />

      <Card class="p-3">
        <p class="font-bold text-lg">Brand Colors</p>
        <p class="mb-3">Select or customise your brand colour.</p>
        <Tab
          :tabs="tabs"
          border
          :active-tab="currentTab"
          @currentTab="currentTab = $event"
        />
        <div class="h-full">
          <Swatches
            type="sideNav"
            v-if="currentTab === 'Side Navigation'"
            :colors="sideNavInitialColors"
            inputPlaceHolder='Try "blue" or "#4F46E5"'
            :selectedColor="appearance.navigationColor || defaultNavColor"
            @update-appearance="updateAppearance"
          />

          <Swatches
            type="buttons"
            v-if="currentTab === 'Buttons'"
            :colors="buttonInitialColors"
            inputPlaceHolder="#4F46E5"
            perPlaceHolder="100%"
            :selectedColor="appearance.buttonColor || defaultBTNColor"
            @update-appearance="updateAppearance"
          />
        </div>
      </Card>

      <div class="w-full h-16 flex justify-end items-end gap-1">
        <Button
          class="text-white w-36 h-11 flex justify-center items-center"
          @click="saveApperanceChanges"
          :disabled="disableBtn"
          :background="appearance.buttonColor || defaultBTNColor"
        >
          Save Changes
        </Button>
        <Button
          @click="cancelChanges"
          :disabled="disableCancelBtn"
          class="text-darkPurple bg-white w-20 h-11 flex justify-center items-center"
        >
          Cancel
        </Button>
      </div>
    </div>
    <div v-else>
      <error-page />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import { lazyLoadInternalComp } from "@/utilities/lazyLoadComponent";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "Appearance",
  components: {
    Card: lazyLoadInternalComp("Card"),
    Tab,
    ErrorPage,
    Banner: () => import("./Banner"),
    Swatches: () => import("./Swatches"),
    Button: lazyLoadInternalComp("Button"),
  },
  data() {
    return {
      sideNavInitialColors: [
        "#321C3B",
        "#EF4444",
        "#F97316",
        "#FACC15",
        "#4ADE80",
        "#2DD4BF",
        "#3B82F6",
      ],
      buttonInitialColors: [
        "#F15A29",
        "#EC4899",
        "#F43F5E",
        "#D946EF",
        "#8B5CF6",
        "#0EA5E9",
        "#10B981",
        "#84CC16",
      ],
      currentTab: "Side Navigation",
      logoEndpoint: process.env.VUE_APP_IMAGE_UPLOAD,
      bannerEndPoint: process.env.VUE_APP_BANNER_UPLOAD,
      disableBtn: false,
      disableCancelBtn: false,
      showError: false,
    };
  },

  computed: {
    tabs() {
      return ["Side Navigation", "Buttons"];
    },
    ...mapState({
      defaultNavColor: (state) => state.orgAppearance.defaultSideNavColor,
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    updateAppearance(item, type) {
      this.$store.dispatch("updateOrgAppearance", { item, type });
    },

    async saveApperanceChanges() {
      const { logoUrl, bannerUrl, navigationColor, buttonColor } =
        this.appearance;
      const payload = { logoUrl, bannerUrl, navigationColor, buttonColor };

      this.disableBtn = true;
      try {
        await this.$_saveOrgAppearance(payload);
        this.disableBtn = false;

        return this.$toasted.success(`Changes successfully made.`, {
          duration: 6000,
        });
      } catch (err) {
        this.disableBtn = false;
        return this.$toasted.error(
          `Error trying to save changes please check your internet conncetion`,
          {
            duration: 6000,
          }
        );
      }
    },

    async cancelChanges(destory = false) {
      this.disableCancelBtn = true;
      try {
        await this.$store.dispatch("getOrgAppearance");
        this.disableCancelBtn = false;

        if (!destory) {
          return this.$toasted.success(`Changes successfully canceled.`, {
            duration: 6000,
          });
        }
      } catch (err) {
        this.disableCancelBtn = false;
        if (!destory) {
          return this.$toasted.error(
            `Error trying to cancel changes please check your internet conncetion`,
            {
              duration: 6000,
            }
          );
        }
      }
    },
  },

  watch: {
    appearance(newAppearance, oldAppearance) {
      let prevNavColorLen = newAppearance.previousNavColor.length || 0;
      let prevBtnColorLen = newAppearance.previousBtnColor.length || 0;
      let iniNavColorLen = this.sideNavInitialColors.length;
      let initBtnColorLen = this.buttonInitialColors.length;

      if (prevNavColorLen > 0 && prevNavColorLen < iniNavColorLen) {
        let shortenNavInitColors = this.sideNavInitialColors.slice(
          0,
          iniNavColorLen - prevNavColorLen
        );
        this.sideNavInitialColors = [
          ...new Set([
            ...shortenNavInitColors,
            ...newAppearance.previousNavColor,
          ]),
        ];
      }

      if (prevNavColorLen >= iniNavColorLen) {
        this.sideNavInitialColors = [...newAppearance.previousNavColor];
      }

      if (prevBtnColorLen > 0 && prevBtnColorLen < initBtnColorLen) {
        let shortenButtonInitColors = this.buttonInitialColors.slice(
          0,
          initBtnColorLen - prevBtnColorLen
        );
        this.buttonInitialColors = [
          ...new Set([
            ...shortenButtonInitColors,
            ...newAppearance.previousBtnColor,
          ]),
        ];
      }

      if (prevBtnColorLen >= initBtnColorLen) {
        this.buttonInitialColors = [...newAppearance.previousBtnColor];
      }
    },
  },

  async destroyed() {
    await this.cancelChanges(true);
  },
  async mounted() {
    try {
      await this.$handlePrivilege("appearanceSetting", "accessAppearanceSettings");
      this.showError = false;
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>

<style scoped>
.drop-container {
  box-shadow: 0px 4px 16px 0px #2222221f;
  position: relative;
}

.drop-container::before {
  content: " ";
  position: absolute;
  width: 1px;
  height: calc(100% - 32px);
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #d6dbe4;
}

.line {
  border: 0.5px solid #d6dbe4;
  width: 90%;
  height: 0;
  margin: auto;
  padding: 0;
}
</style>
